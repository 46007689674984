import {Component, Inject, OnInit} from '@angular/core'
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog'
import {TimeTrackerService} from '../../services/time-tracker.service'
import {DateUtils} from '../../util/date-utils'

export interface SelectOption<T> {
  displayName: string
  value: T
}

@Component({
  selector: 'app-balance-history-dialog',
  templateUrl: './balance-history-dialog.component.html',
  styleUrls: ['./balance-history-dialog.component.scss']
})
export class BalanceHistoryDialogComponent implements OnInit {
  protected readonly DateUtils = DateUtils
  protected readonly Date = Date

  userName: string
  balances: { period: string, isChange: boolean, saldo: number, comment: string }[] = []
  filteredBalances: { period: string, saldo: number, comment: string }[] = []
  yearOptions: SelectOption<number>[] = []
  selectedYear: number
  userId: number
  from: string
  to: string
  loading = true
  error = false

  currentYear: number

  columns = [
    {displayName: 'Zeitraum', fieldName: 'period', sortable: true},
    {displayName: 'Saldo', fieldName: 'saldo', sortable: true},
    {displayName: 'Bemerkung Backoffice', fieldName: 'comment', sortable: false}
  ]

  constructor(
    public dialogRef: MatDialogRef<BalanceHistoryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private timeTrackerService: TimeTrackerService
  ) {
    this.userName = data.name
    this.userId = data.userId
    this.currentYear = new Date().getFullYear()
    this.from = `${this.currentYear - 2}-01-01`
    this.to = `${this.currentYear + 2}-12-31`
  }

  ngOnInit(): void {
    this.timeTrackerService.getBalancesWithComments(this.userId, this.from, this.to).subscribe(balances => {
      this.balances = balances.map(b => ({
        period: b.month,
        isChange: b.balance && b.balance !== 0,
        saldo: (b.balance && b.balance !== 0) ? b.balance : b.saldoChange,
        comment: (b.balance && b.balance !== 0 ? 'Eingereicht: ' : 'Offen: ') + (b.comment.length > 0 ? b.comment : '--')
      }))

      this.yearOptions = [...new Set(this.balances
        .map(b => new Date(b.period).getFullYear())
        .filter(year => !isNaN(year))
      )]
        .sort((a, b) => b - a)
        .map(year => ({displayName: year.toString(), value: year}))

      this.selectedYear = this.yearOptions.find(option => option.value === this.currentYear)?.value
        ?? (this.yearOptions.length > 0 ? this.yearOptions[0].value : this.currentYear)

      this.filterByYear()
      this.loading = false
    }, error => {
      this.loading = false
      this.error = true
      console.error('Error loading balance history:', error)
    })
  }

  onYearChange(year: number): void {
    this.selectedYear = year
    this.filterByYear()
  }

  filterByYear(): void {
    this.filteredBalances = this.balances.filter(balance => {
      const balanceYear = new Date(balance.period).getFullYear()
      return balanceYear === this.selectedYear
    })
  }

  onClose(): void {
    this.dialogRef.close()
  }

  filterFunction(data: any, filter: string): boolean {
    return data.period.toLowerCase().includes(filter.toLowerCase()) ||
      data.saldo.toString().toLowerCase().includes(filter.toLowerCase()) ||
      data.comment.toLowerCase().includes(filter.toLowerCase())
  }
}
