<h1 mat-dialog-title>
  {{ userName }} – Saldoverlauf
  <app-select
    class="year-select"
    [options]="yearOptions"
    [(selectedOption)]="selectedYear"
    (selectedOptionChange)="onYearChange($event)"
    placeholder="Jahr auswählen">
  </app-select>
</h1>

<div class="balance-history-container">
  <app-sortable-table
    class="balance-history-sortable-table"
    [columnDefinitions]="columns"
    [columnSizes]="['24%', '12%', '64%']"
    [data]="filteredBalances"
    [filterPredicate]="filterFunction"
    [disablePaginator]="true"
  >
    <ng-template #Zeitraum let-data>
      <span>{{ DateUtils.getMonthNameAndYear(DateUtils.stringToDate(data.period)) }}</span>
    </ng-template>

    <ng-template #Saldo let-data>
      <div class="saldo-container">
        <span *ngIf="data.isChange" [ngClass]="data.saldo | balanceColor">{{ data.saldo | balanceFormat }}</span>
        <span *ngIf="!data.isChange" [ngClass]="data.saldo | balanceColor">X {{ data.saldo | balanceFormat }}</span>
      </div>
    </ng-template>

    <ng-template #BemerkungBackoffice let-data>
      <span>{{ data.comment }}</span>
    </ng-template>
  </app-sortable-table>
</div>

<ng-template #loadingTemplate>
  <div class="loading-container">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>

<div mat-dialog-actions>
  <button mat-button (click)="onClose()" class="rounded-button">Schließen</button>
</div>
