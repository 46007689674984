import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'balanceColor',
  standalone: true
})
export class BalanceColorPipe implements PipeTransform {

  transform(balance: number): string {
    if (balance === 0) {
      return ''
    }

    if (balance == null || isNaN(balance)) {
      return '';
    }

    const absoluteBalance = Math.abs(balance)

    if (balance < 0) {
      return 'negative'
    } else if (absoluteBalance > 40 * 3600) {
      return 'warning'
    } else {
      return 'positive'
    }
  }
}
