import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'balanceFormat',
  standalone: true
})
export class BalanceFormatPipe implements PipeTransform {

  transform(balance: number): string {
    if (balance === 0 || balance == null || isNaN(balance)) {
      return '+ 0:00'
    }

    const isNegative = balance < 0
    const absoluteBalance = Math.abs(balance)
    const hours = Math.floor(absoluteBalance / 3600)
    const minutes = Math.floor((absoluteBalance % 3600) / 60)
    const minutesDisplay = minutes < 10 ? `0${minutes}` : `${minutes}`
    const display = `${hours}:${minutesDisplay}`
    const prefix = isNegative ? '- ' : '+ '

    return `${prefix}${display}`
  }

}
